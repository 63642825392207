<style lang="less" scoped>
  .doc-item {
    background-color: #FFF;
    border-radius: 5px;
    margin: 10px;
    border: 1px solid #EEE;
    padding: 10px;
  }
  .row {
    display: flex;
    & + .row {
      margin-top: 5px;
    }
  }
  .name {
    color: #999;
  }
  .value {
    color: #657180;
  }
  .icon-gongwen {
    font-size: 25px;
    margin-right: 10px;
    font-weight: 800;
  }
</style>

<template>
  <div class="wap-doc-handle">
    <router-link tag="div" :to="{
      name: 'wap.doc.detail', query: { id: item.officialDocumentId, handle: 1 }
    }" class="doc-item" v-for="(item, i) in [...dataList]" :key="i">
      <div class="row">
        <i class="iconfont icon-gongwen"></i>
        <div style="flex: 1;">
          <div class="row">
            <span class="name">编号:</span>
            <span class="value">{{item.officialDocumentId}}</span>
          </div>
          <div class="row">
            <span class="name">标题：</span>
            <span class="value">{{item.officialDocumentTitle}}</span>
          </div>
        </div>
        <div>{{item.status === 'wait' ? '待办' : '已办'}}</div>
      </div>
      <div class="row">
        <div style="flex: 1;">
          <span class="name">来文单位：</span>
          <span class="value">{{item.sendOrgName}}</span>
        </div>
        <div style="flex: 1;">
          <span class="name">来文份数：</span>
          <span class="value">{{item.officialDocumentNum}}</span>
        </div>
      </div>
      <div class="row">
        <div style="flex: 1;">
          <span class="name">来文日期：</span>
          <span class="value">{{item.officialDocumentSendTime ? item.officialDocumentSendTime.slice(0, 10) : '-'}}</span>
        </div>
        <div style="flex: 1;">
          <span class="name">来文号：</span>
          <span class="value">{{item.officialDocumentSendCode}}</span>
        </div>
      </div>
    </router-link>
  </div>
</template>

<script>
import { officialDocumentHandleRequest } from '@/api'

export default {
  data () {
    return {
      dataList: []
    }
  },
  methods: {
    async loadData () {
      let datas = await officialDocumentHandleRequest.getMy()
      let dataMap = {}
      datas.forEach(v => {
        let key = v.officialDocumentId + '_' + v.workerId
        if (!dataMap[key]) {
          dataMap[key] = v
        } else {
          dataMap[key].status = v.status === 'over' ? 'over' : 'wait'
        }
      })
      this.dataList = Object.keys(dataMap).map(v => dataMap[v])
    },
  },
  activated () {
    this.loadData()
  }
}
</script>
